.masthead {
  justify-content: space-between;
  align-items: center;
  text-transform: lowercase;
  padding: 24px 0 0;
  margin-bottom: 80px;
}

.masthead__logo {
  margin: 0;
  font-size: 20px;
}

.masthead__logo-link {
  display: flex;
  align-items: center;
  color: var(--text-color);
  font-weight: 500;
  text-decoration: none;
}

.masthead__logo-mark {
  margin-right: 12px;
}

.logo-bkg {
  fill: var(--yellow);
  transition: fill 1s cubic-bezier(0, 0, 0.06, 1.06);
}

.masthead__logo-link:hover .logo-bkg {
  fill: var(--beige);
}

.nav-toggle {
  display: none;
}

.main-nav__list {
  list-style: none;
  margin: 0;
}

.main-nav__list-item {
  font-size: 20px;
  margin-bottom: 0;
}

.main-nav__list-item a {
  color: var(--text-color);
  font-weight: 500;
  text-decoration: none;
}

.main-nav__list-item a,
.masthead__logo-text {
  display: block;
  position: relative;
  overflow: hidden;
}

.main-nav__list-item a::after, 
.masthead__logo-text::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--dark-rust);
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.2s ease-out;
}

.main-nav__list-item a:hover::after,
.main-nav__list-item a:focus::after,
.masthead a:hover .masthead__logo-text::after,
.masthead a:focus .masthead__logo-text::after {
  transform: translate3d(0, 0, 0);
}

@media (--mq-small) {
  .masthead {
    margin-bottom: 160px;
  }

  /* .main-nav {
    position: relative;
    z-index: 1;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    background-color: transparent;
    visibility: visible;
  }   */

  .main-nav__close {
    display: none;
    transition: none;
  }

  .main-nav__list-item {
    margin-left: 36px;
    margin-bottom: 0;
  }
}

/* @media (--mq-xsmall) {
  .main-nav {
    position: fixed;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: transparent;
    visibility: hidden;
    transition: all .3s;
  }

  .main-nav__list {
    flex-direction: column;
    margin-top: 24px;
    padding-left: 0;
  }
  
  .nav-toggle {
    display: block;
  }

  .main-nav__close {
    display: block;
    transition: none;
    margin-left: auto;
  }

  .nav-active {
    visibility: visible;
  }
  
  .main-nav__inner {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 75%;
    min-width: 300px;
    padding: 26px 24px;

    background-color: var(--bg-color);
    box-shadow: -2px 2px 27px rgba(0, 0, 0, 0.14);
  
    transform: translate3d(100%,0,0);
    transition: transform .3s ease-in-out;
  }
  
  .nav-active .main-nav__inner {
    transform: translateZ(0);
  }
  
} */