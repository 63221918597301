
/* MQ Breakpoints */
/* 320px */
@custom-media --mq-xsmall (max-width: 30em);
/* 480px */
@custom-media --mq-small (min-width: 30em);
/* 768px */
@custom-media --mq-med (min-width: 48em);
/* 1024px */
@custom-media --mq-lg (min-width: 64em);


:root {
  /* Colours */
  --dark-green: #1F231A;
  --grey: #5F5F5F;
  --beige: #FBF7F4;
  --rust: #D65D0E;
  --dark-rust: #BA4F08;
  --peach: #E49D84;
  --yellow: #FD9F1F;

  /* Colour usage */
  --bg-color: var(--beige);
  --text-color: var(--dark-green);
  --text-color-light: var(--grey);
  --link-color: var(--dark-rust);

  /* Type */
  --h1: 32px;
  --h2: 28px;
  --h3: 26px;
  --h4: 20px;
  --p-lead: 20px;
  --p: 18px;
}

@media (--mq-med) {
  :root {
    --h1: 48px;
    --h2: 36px;
    --h3: 30px;
    --h4: 20px;
    --p-lead: 20px;
    --p: 18px;
  }
}
