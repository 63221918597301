/* Case study pages */

.csp-header > .wrapper {
  text-align: center;
  overflow-y: hidden;
}

.csp-header p {
  margin-left: auto;
  margin-right: auto;
}

.csp-header__img {
  display: block;
  border-radius: 8px 8px 0 0;
  /* box-shadow: 0 -4px 12px rgba(0,0,0,0.1); */
  margin-top: 32px;
}

.csp-header__info-grid p:first-child { grid-area: 1 / 1 / 2 / 2; }
.csp-header__info-grid p:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
.csp-header__info-grid p:last-child { grid-area: 1 / 3 / 2 / 6; }

.cs-section {
  margin: 36px 0;
}

.cs-spacer {
  margin-top: 24px;
  margin-bottom: 24px;
}
  
@media (--mq-med) {

  .cs-header__byline {
      margin-bottom: 32px;
  }
  
  .csp-header__img {
    margin-top: 56px;
  }

  .cs-section {
    margin: 80px 0;
  }

  .cs-spacer {
    margin-top: 56px;
    margin-bottom: 56px;
  }
}
  