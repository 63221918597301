/* @font-face {
	font-family: 'Manrope';
	src: url('/src/assets/fonts/Manrope.woff2') format('woff2') tech('variations'),
	     url('/src/assets/fonts/Manrope.woff2') format('woff2-variations');
	font-weight: 100 1000;
} */

h1,h2,h3,h4,h5,h6 {
  font-weight: 500;
  margin-top: 0;
}

h1,.h1,h2,.h2 {
  margin-bottom: 32px;
  line-height: 1.3;
  letter-spacing: -0.01em;
}

h3,.h3,h4,.h4,h5,.h5 {
  margin-bottom: 16px;
  line-height: 1.3;
}

h1, .h1 {
  font-size: var(--h1);
  font-weight: 400;
}

h2, .h2 {
  font-size: var(--h2);
}

h3, .h3 {
  font-size: var(--h3);
}

h4, .h4 {
  font-size: var(--h4);
  font-weight: 700;
}

h5, .h5 {
  font-size: var(--p-lead);
}

p, ul, ol, dl {
  font-size: var(--p);
  line-height: 1.55;
  margin-bottom: 16px;
}

p + h2,
p + h3 {
  margin-top: 32px;
}

ul,ol {
  padding-left: 24px;
}

li {
  margin-bottom: 8px;
}

dd {
  margin: 0;
}

.lead {
  font-size: var(--p-lead);
}

figcaption {
  color: var(--text-color-light);
  font-size: 16px;
  padding: 12px 0;
}

/* @media (--mq-med) {
  h1, .h1 {
    font-size: var(--h1-lg);
    margin-bottom: 32px;
  }
  
  h2, .h2 {
    font-size: var(--h2-lg);
  }
  
  h3, .h3 {
    font-size: var(--h3-lg);
  }

  h4, .h4 {
    font-size: var(--h4-lg);
  }

  p {
    font-size: var(--p-lg);
  }

  .lead {
    font-size: var(--p-lead-lg);
  }
} */

a {
  color: var(--link-color);
}

a:hover,
a:focus {
  text-decoration: none;
}

.fw-med {
  font-weight: 500;
}

