.list-none {
  list-style: none;
  padding-left: 0;
}

.sr-only {
  position: absolute;
  left: -9999px;
  top: 0;
  padding: 1rem;
  color: var(--beige);
  background: var(--dark-rust);
  white-space: nowrap;
  z-index: 1;
}

.sr-only:focus {
  left: 1rem;
}

.mw {
  max-width: 682px;
}

.mb-none {
  margin-bottom: 0;
}

.m-none {
  margin: 0;
}

.brow {
  text-transform: uppercase;
  color: var(--dark-rust);
}

.fm {
  font-weight: 500;
}

.grey {
  color: var(--grey);
}

.rounded {
  border-radius: 8px;
}

.shadow {
  box-shadow: 2px 5px 20px rgb(0 0 0 / 4%)
}

@keyframes fadein {
  0% { opacity: 0; }
  100% { opacity:  1; }
}