html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

figcaption, figure, main {
  display: block;
}

figure {
  margin: 1em 2.5rem;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background-color: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
  display: inline-block;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details, menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template, [hidden] {
  display: none;
}

:root {
  --dark-green: #1f231a;
  --grey: #5f5f5f;
  --beige: #fbf7f4;
  --rust: #d65d0e;
  --dark-rust: #ba4f08;
  --peach: #e49d84;
  --yellow: #fd9f1f;
  --bg-color: var(--beige);
  --text-color: var(--dark-green);
  --text-color-light: var(--grey);
  --link-color: var(--dark-rust);
  --h1: 2rem;
  --h2: 1.75rem;
  --h3: 1.625rem;
  --h4: 1.25rem;
  --p-lead: 1.25rem;
  --p: 1.125rem;
}

@media (min-width: 48em) {
  :root {
    --h1: 3rem;
    --h2: 2.25rem;
    --h3: 1.875rem;
    --h4: 1.25rem;
    --p-lead: 1.25rem;
    --p: 1.125rem;
  }
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  font-family: Manrope, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif;
  line-height: 1.55;
}

img {
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  font-weight: 500;
}

h1, .h1, h2, .h2 {
  letter-spacing: -.01em;
  margin-bottom: 2rem;
  line-height: 1.3;
}

h3, .h3, h4, .h4, h5, .h5 {
  margin-bottom: 1rem;
  line-height: 1.3;
}

h1, .h1 {
  font-size: var(--h1);
  font-weight: 400;
}

h2, .h2 {
  font-size: var(--h2);
}

h3, .h3 {
  font-size: var(--h3);
}

h4, .h4 {
  font-size: var(--h4);
  font-weight: 700;
}

h5, .h5 {
  font-size: var(--p-lead);
}

p, ul, ol, dl {
  font-size: var(--p);
  margin-bottom: 1rem;
  line-height: 1.55;
}

p + h2, p + h3 {
  margin-top: 2rem;
}

ul, ol {
  padding-left: 24px;
}

li {
  margin-bottom: .5rem;
}

dd {
  margin: 0;
}

.lead {
  font-size: var(--p-lead);
}

figcaption {
  color: var(--text-color-light);
  padding: 12px 0;
  font-size: 1rem;
}

a {
  color: var(--link-color);
}

a:hover, a:focus {
  text-decoration: none;
}

.fw-med {
  font-weight: 500;
}

.page {
  margin-bottom: 4rem;
}

.wrapper {
  max-width: 61.875rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
  display: block;
  position: relative;
}

.wrapper--wide {
  max-width: 1192px;
}

.content-narrow {
  max-width: 786px;
  margin: 0 auto;
}

.content-mid {
  max-width: 980px;
  margin: 0 auto;
}

section {
  margin-bottom: 2rem;
}

.grid {
  gap: 16px;
  display: grid;
}

.flex {
  display: flex;
}

.cols-2third {
  max-width: 786px;
}

.cols-span-2 {
  grid-column: span 2 / span 2;
}

.cols-half {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

@media (min-width: 48em) {
  .wrapper {
    padding: 0 32px;
  }

  .grid {
    gap: 30px;
  }

  .cols-half-md {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .cols-thirds-md {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

figure {
  margin: 0;
}

a:focus, button:focus, input:focus {
  outline-offset: 2px;
  outline: 2px solid #bc6152;
}

button {
  background-color: var(--peach);
  -webkit-appearance: none;
  border: 0;
  border-radius: 20px;
  padding: 8px 12px;
  font-family: DM Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif;
  font-size: 1.25rem;
}

.masthead {
  text-transform: lowercase;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5rem;
  padding: 24px 0 0;
}

.masthead__logo {
  margin: 0;
  font-size: 1.25rem;
}

.masthead__logo-link {
  color: var(--text-color);
  align-items: center;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}

.masthead__logo-mark {
  margin-right: .75rem;
}

.logo-bkg {
  fill: var(--yellow);
  transition: fill 1s cubic-bezier(0, 0, .06, 1.06);
}

.masthead__logo-link:hover .logo-bkg {
  fill: var(--beige);
}

.nav-toggle {
  display: none;
}

.main-nav__list {
  margin: 0;
  list-style: none;
}

.main-nav__list-item {
  margin-bottom: 0;
  font-size: 1.25rem;
}

.main-nav__list-item a {
  color: var(--text-color);
  font-weight: 500;
  text-decoration: none;
}

.main-nav__list-item a, .masthead__logo-text {
  display: block;
  position: relative;
  overflow: hidden;
}

.main-nav__list-item a:after, .masthead__logo-text:after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: var(--dark-rust);
  transition: transform .2s ease-out;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate3d(-100%, 0, 0);
}

.main-nav__list-item a:hover:after, .main-nav__list-item a:focus:after, .masthead a:hover .masthead__logo-text:after, .masthead a:focus .masthead__logo-text:after {
  transform: translate3d(0, 0, 0);
}

@media (min-width: 30em) {
  .masthead {
    margin-bottom: 10rem;
  }

  .main-nav__close {
    transition: none;
    display: none;
  }

  .main-nav__list-item {
    margin-bottom: 0;
    margin-left: 2.25rem;
  }
}

.page-footer {
  background-color: #fbf2e9;
  padding: 24px 0;
}

.links li {
  margin-right: 1rem;
}

.hero {
  padding-bottom: 32px;
}

@media (min-width: 48em) {
  .hero {
    padding-bottom: 112px;
  }
}

.work {
  background-image: url("cs-shape.3fd596d4.png");
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: 100%;
}

.cs-head-wrap {
  background-color: #fbebdd80;
}

.cs-block {
  align-items: center;
  margin-bottom: 1.5rem;
  position: relative;
}

.cs-block__link {
  color: var(--text-color);
  text-decoration: none;
  display: inline;
}

.cs-block__link:before {
  content: "";
  white-space: nowrap;
  z-index: 1;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.cs-block__link:hover, .cs-block__link:focus {
  text-decoration: underline;
  text-decoration-color: var(--dark-rust);
}

.cs-block__header {
  margin-bottom: 1rem;
}

@media (min-width: 48em) {
  .cs-block {
    margin-bottom: 3rem;
  }

  .cs-block:nth-child(2n) .cs-block__img-wrap {
    order: 2;
  }
}

@media (min-width: 64em) {
  .cs-block:nth-child(2n+1) {
    margin-left: -2rem;
  }

  .cs-block:nth-child(2n+1) .cs-block__text {
    margin-left: 3rem;
  }

  .cs-block:nth-child(2n) {
    margin-right: -2rem;
  }

  .cs-block:nth-child(2n) .cs-block__text {
    margin-right: 3rem;
  }
}

.home, .about {
  background-image: url("shape.313433a2.webp");
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: 100%;
}

@media (min-width: 30em) {
  .home {
    background-size: 50%;
  }
}

.about {
  background-image: url("about-shape.a673d6c7.webp");
}

.about-photo {
  border-radius: 50%;
}

.cv-list {
  gap: 0;
}

.cv-list dd, .cv-list dt {
  border-bottom: 1px solid var(--peach);
  padding: 32px 0;
}

.cv-list dd:last-of-type, .cv-list dt:last-of-type {
  border-bottom: none;
}

.csp-header > .wrapper {
  text-align: center;
  overflow-y: hidden;
}

.csp-header p {
  margin-left: auto;
  margin-right: auto;
}

.csp-header__img {
  border-radius: 8px 8px 0 0;
  margin-top: 2rem;
  display: block;
}

.csp-header__info-grid p:first-child {
  grid-area: 1 / 1 / 2 / 2;
}

.csp-header__info-grid p:nth-child(2) {
  grid-area: 1 / 2 / 2 / 3;
}

.csp-header__info-grid p:last-child {
  grid-area: 1 / 3 / 2 / 6;
}

.cs-section {
  margin: 2.25rem 0;
}

.cs-spacer {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 48em) {
  .cs-header__byline {
    margin-bottom: 2rem;
  }

  .csp-header__img {
    margin-top: 3.5rem;
  }

  .cs-section {
    margin: 5rem 0;
  }

  .cs-spacer {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
}

.list-none {
  padding-left: 0;
  list-style: none;
}

.sr-only {
  color: var(--beige);
  background: var(--dark-rust);
  white-space: nowrap;
  z-index: 1;
  padding: 1rem;
  position: absolute;
  top: 0;
  left: -9999px;
}

.sr-only:focus {
  left: 1rem;
}

.mw {
  max-width: 682px;
}

.mb-none {
  margin-bottom: 0;
}

.m-none {
  margin: 0;
}

.brow {
  text-transform: uppercase;
  color: var(--dark-rust);
}

.fm {
  font-weight: 500;
}

.grey {
  color: var(--grey);
}

.rounded {
  border-radius: 8px;
}

.shadow {
  box-shadow: 2px 5px 20px #0000000a;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}



/*# sourceMappingURL=main.css.map */
