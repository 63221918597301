a:focus,
button:focus,
input:focus {
    outline: 2px solid #BC6152;
    outline-offset: 2px;
}


button {
    background-color: var(--peach);
    border-radius: 20px;
    border: 0;
    font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif; 
    font-size: 20px;
    padding: 8px 12px;
    -webkit-appearance: none;
}