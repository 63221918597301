.page {
  margin-bottom: 64px;
}

.wrapper {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
  max-width: 61.875rem;
  position: relative;
}

.wrapper--wide {
  max-width: 1192px;
}

.content-narrow {
  max-width: 786px;
  margin: 0 auto;
}

.content-mid {
  max-width: 980px;
  margin: 0 auto;
}

section {
  margin-bottom: 32px;
}

.grid {
  display: grid;
  gap: 16px;
}

.flex {
  display: flex;
}

.cols-2third {
  max-width: 786px;
}

.cols-span-2 {
  grid-column: span 2 / span 2;
}


.cols-half {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

@media (--mq-med) {
  .wrapper {
    padding: 0 32px;
  }

  .grid {
    gap: 30px;
  }

  .cols-half-md {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .cols-thirds-md {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

figure {
  margin: 0;
}